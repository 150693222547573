import React from 'react'
import Layout from '../components/Layout/Layout'
import { Helmet } from 'react-helmet'

const Careers = () => {
  return (
    <Layout noFooter title="College Pulse Careers">
      <section className="is-centered">
        <Helmet>
          <title>College Pulse | Careers</title>
          <meta property="og:image" content="/cp-website-min.jpg" />
        </Helmet>
        <div style={{ width: '100%', marginTop: 40 }}>
          <iframe
            frameborder="0"
            scrolling="yes"
            width="100%"
            style={{ height: 'calc(100vh - (71px + 40px))' }}
            src="https://wordpress.collegepulse.com/jobs"
            title="jobs"
          />
        </div>
      </section>
    </Layout>
  )
}

export default Careers
